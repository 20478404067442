
				import Component from "/src/templates/NotFound.svelte";
				import { createCart } from "/src/stores/cart/createCart";
				const originalOnload = window.onload;
				window.onload = (ev) => {
					const props = JSON.parse(window.document.querySelector("#props").innerText);
					const globalSettings = JSON.parse(window.document.querySelector("#global-settings").innerText);
					const context = new Map();
					context.set("globalSettings", globalSettings);
					context.set("breadcrumbs", props.breadcrumbs);
					context.set("pageSlug", props.pageSlug);
					context.set("menu", props.menu);
					context.set("title", props.title);
					context.set("description", props.description);
					context.set("keywords", props.keywords);
					context.set("announcement", props.announcement);
					context.set("smartlookEnabled", props.smartlookEnabled);
					context.set("requiredCategories", props.requiredCategories);
					context.set("index", props.index);
					context.set("cartStore", createCart(props.requiredCategories));
					void new Component({ target: document.body, hydrate: true, props, context });
					originalOnload?.(ev);
				};
			